@import "../../../global-styles/variables";

.wrapper {
  composes: block-after from "../../../global-styles/block-visuals.module.css";

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 44vh;
  min-height: 30rem;
  width: 22vw;
  min-width: 18rem;
  background-color: white;
  --messageContainerSidePadding: 1rem;
  padding: 0 var(--messageContainerSidePadding) 1rem var(--messageContainerSidePadding);
  border-radius: var(--borderRadiusBig);
  overflow: hidden;
  margin-top: auto;

  @include mobile {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.sendButton {
  cursor: pointer;
  display: flex;
  color: white;
  border-radius: 1rem;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem 0 1rem;
  font-size: 1rem;
  background-color: var(--blue);
  outline: none;
}

.sendButton > * {
  margin-bottom: 0.8rem;
}

.sendButton > *:last-child {
  margin-bottom: 0;
}

.sendButton:hover {
  opacity: 0.7;
}

.sendButton i {
  font-size: 1.3rem;
  margin-left: 0.5rem;
}

.bottomPart {
  margin-top: 1rem;
  display: flex;
}

.bottomPart > *:last-child {
  margin-bottom: 0;
}

.input {
  line-height: 1.5rem;
  background-color: var(--blueBackground);
  border-radius: 1rem;
  height: 2rem;
  min-height: min-content;
  max-height: 10rem;
  font-size: 1rem;
  width: 100%;
  outline: none;
  padding: 0.25rem 1rem 0.25rem 1rem;
  resize: none;
  box-sizing: border-box;
  overflow-y: hidden;
  margin-right: 1rem;
}

.messagesContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  overflow-y: scroll;
  padding-right: 40px;
  padding-top: 2rem;
}

.messagesContainer > * {
  margin-bottom: 0.3rem;
}

.messagesContainer > *:last-child {
  margin-bottom: 0;
}

.top {
  border-radius: var(--borderRadiusBig) var(--borderRadiusBig) 0 0;
  font-size: 1.2rem;
  font-weight: bold;
  width: calc(100% + 2 * var(--messageContainerSidePadding));
  padding: 0.5rem 0 0.5rem 0;
  margin-left: calc(-1 * var(--messageContainerSidePadding));
  margin-right: calc(-1 * var(--messageContainerSidePadding));
  border-bottom: 1px solid rgb(212, 212, 212);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 1rem 0;
  }
}

.cantSendTextContainer {
  display: flex;
  flex-flow: column;
  font-size: 1rem;
  text-align: center;
  margin-top: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.disabledChildren > * {
  opacity: 0.6;
}

.cantSendTextContainer i {
  font-size: 3rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.cantSendTextContainer strong {
  font-size: 1rem;
}

.msgBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .timestamp {
    color: gray;
    font-size: 0.9rem;
    margin-bottom: 2px;
    text-transform: capitalize;
    display: none;
  }

  .content {
    display: flex;
    font-size: 1rem;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: max-content;
    max-width: 60%;
    word-break: break-word;
    border-radius: 1rem;
    padding: 0.3rem 1rem 0.3rem 1rem;
  }

  &:hover {
    *.timestamp {
      display: flex;
    }
  }

  &.received {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .timestamp {
      margin-left: 0.5rem;
      text-align: left;
    }
    .content {
      background-color: var(--blueBackground);
    }
  }

  &.sent {
    flex-direction: row;
    justify-content: flex-end;
    .timestamp {
      margin-right: 0.5rem;
      text-align: right;
    }
    .content {
      background-color: var(--blue);
      color: white;
    }
  }
}
