.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  --iconWidth: 3rem;
  flex: 1;
}

.body {
  --bodyPadding: 1rem;
  display: flex;
  flex-direction: column;
  padding: var(--bodyPadding);
  height: 100%;
  flex: 1;
}

.body > div {
  display: flex;
  margin-bottom: 1rem;
}

.body > div i {
  margin-right: 0.6rem;
}

.body i {
  padding: 0;
  margin: 0;
  color: rgb(97, 97, 97);
}

.body > *:last-child {
  margin-top: auto;
  margin-left: auto;
}

.container :global(.Mui-disabled) {
  color: black;
}

.buttonContainer {
  padding-top: 1.5rem;
}

.buttonContainer > *:last-child {
  margin-left: 1rem;
}

.popupContent {
  width: 100%;
  text-align: center;
}
