.link {
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: var(--blueBackground);
  width: max-content;
  margin-top: 0.5rem;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  transition: opacity 0.1s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.link:first-child {
  margin-top: 0;
}

.link i {
  color: #666;
  font-size: 1.1rem;
  margin-right: 0.3rem;
  margin-left: -0.2rem;
}

.link:hover {
  opacity: 0.7;
}

#label {
  margin: 0;
  padding: 0;
}
