.labelCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}

.labelCont p {
  flex-grow: 1;
  text-align: center;
  padding: 0.25rem 0.5rem;
  margin-right: 0.2rem;
  margin-bottom: 0.3rem;
  border: 1px solid rgba(95, 95, 95);
  border-radius: 0.3rem;
  font-size: 0.9rem;
  color: rgb(50, 50, 50);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.labelCont p:hover {
  color: var(--blue);
  border-color: var(--blue);
}
