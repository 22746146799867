@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blocked {
  0% {
    transform: rotateZ(0);
  }
  5% {
    transform: rotateZ(10deg);
  }
  10% {
    transform: rotateZ(-10deg);
  }
  15% {
    transform: rotateZ(0);
  }
  20% {
    transform: rotateZ(0);
  }
}


.container {
  cursor: pointer;
  user-select: none;
  position: relative;
  margin-left: 1rem;

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;

    .blocked {
      background-color: var(--red);
      animation-name: none !important;
    }

  }

  .assignee {
    display: flex;
    height: 3rem;
    width: 3rem;
    border-radius: 1.5rem;
    justify-content: center;
    align-items: center;
    background-color: var(--blue);
    transition: background-color 100ms ease-in-out, opacity 100ms ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  
    &:active {
      opacity: 0.6;
    }
  
    .icon {
      font-size: 1.8rem;
      font-weight: 500;
      color: white;
    }

    &.blocked {
      background-color: var(--red);
      animation-name: blocked;
      animation-iteration-count: infinite;
      animation-delay: 1s;
      animation-duration: 5s;
    }
  }

  .selector {
    position: absolute;
    right: -0.75rem;
    bottom: calc(100% + 10px);
    background-color: white;
    box-shadow: 0 5px 10px 0 #00000020;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    cursor: default;
    animation: appear 150ms ease-in-out;

    &.bottomPlacement {
      bottom: unset;
      top: calc(100% + 10px);
    }

    .button {
      cursor: pointer;
      transition: opacity 100ms ease-in-out, background-color 200ms ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      &:active {
        opacity: 0.6;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--blue);
      border-radius: 2rem;
      width: 3rem; 
      height: 3rem; 
      padding: 0;
      background-color: white;
      border: 2px solid var(--blue);
      box-sizing: border-box;

      &.active {
        background-color: var(--blue);
        color: white;
        border: none;
      }

      &.blocked {
        background-color: var(--red);
      }
    }
  }
}
