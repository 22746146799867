.uploadContainer {
  display: flex;
  align-items: center;
  margin-right: -2rem;
  width: 100%;
}

.uploadContainer span {
  margin-left: 2rem;
  max-width: 15rem;
}

.uploadContainer > * {
  margin-right: 1.5rem;
}

.uploadContainer i {
  font-size: 1.5rem;
  cursor: pointer;
}

.inputLabel i {
  margin-right: 0.6rem;
}

.uploadContainer p {
  font-size: 0.85rem !important;
  font-weight: normal !important;
  align-self: flex-start;
}

.container input {
  display: none;
}

.inputLabel {
  background-color: var(--blueBackground);
  padding: 0 1rem 0 1rem;
  height: 2rem;
  border-radius: var(--borderRadiusBig);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  align-self: flex-start;
  white-space: nowrap;
}

.uploadedLabelsContainer {
  margin-bottom: -1rem;
  width: 100%;
}

.uploadedLabelsContainer > * {
  margin-bottom: 1rem;
}

.errorMsg {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: red;
}
