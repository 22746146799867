@import "../../global-styles/variables";

.container {
  display: flex;
  flex-direction: column;
  --topHeight: 4rem;
  height: 100%;
}

.addEventContainer {
  display: flex;
  overflow: auto;
  background-color: white;
  z-index: 4;

  @include desktop {
    position: absolute;
    width: 22rem;
    min-height: 40rem;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    border-radius: 15px;
    box-shadow: 0 6px 8px 0 rgb(212, 212, 212);
  }

  @include mobile {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  height: var(--topHeight);
  margin-top: -1.5rem;
}

.nameContainer p {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 auto 0 auto;
}

.calendarContainer {
  height: calc(100% - var(--topHeight) + 1.5rem);

  @include mobile {
    width: 100%;
  }
}
