@import "../../global-styles/variables";

.container {
  width: fit-content;
  margin-bottom: 2rem;

  @include mobile {
    max-width: 100%;
  }
}

.container h4 {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

.firstBox {
  margin-bottom: 1rem;
}

.checkboxCont > *:last-child {
  margin-left: 0.5rem;
}
