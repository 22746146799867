.openTicketMenuButton {
  width: 100%;
  margin-top: 2rem;
}

.mobileMenuContainer {
  margin: 0 auto;
  > *:first-child {
    margin-top: 1rem;
  }
  > * {
    margin-bottom: 1rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
