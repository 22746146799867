.notificationContainer {
  transition: opacity var(--fadeOutTime) ease-in-out;
  position: relative;
}

.buttonContainer {
  position: relative;
}

.notificationCircle {
  position: absolute;
  top: -0.3rem;
  right: 0.6rem;
  --height: 1.5rem;
  width: var(--height);
  height: var(--height);
  border-radius: calc(var(--height) * 0.5);
  font-size: calc(var(--height) * 0.6);
  box-sizing: border-box;
}
