@import "../../../global-styles/variables";

.container {
  composes: block-after from "../../../global-styles/block-visuals.module.css";
  --paddingHorizontal: 1.5rem;
  --headerHeight: 3rem;
  padding: calc(var(--headerHeight) + 1rem) var(--paddingHorizontal) 1rem var(--paddingHorizontal);
  position: relative;
  display: flex;
  width: 35rem;
  height: min-content;
  min-height: 5rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: var(--borderRadius);

  @include mobile {
    width: 100%;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  padding: 0 var(--paddingHorizontal);
  box-sizing: border-box;

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
