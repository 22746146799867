.container {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  --markerContainerHeight: 50px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sliderContainer {
  display: flex;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.child {
  height: min-content;
}

.markerContainer {
  display: flex;
  margin: 0 auto;
  height: var(--markerContainerHeight);
  align-items: center;
  width: 100%;
}

.bottomMarkerContainer {
  composes: markerContainer;
  position: absolute;
  bottom: 0;
}

.markerContainer i {
  font-size: 1.8rem;
}

.markerContainer > div {
  margin-right: 0.5rem;
}

.markerContainer > div:last-of-type {
  margin-right: 0;
}

.marker {
  width: 1rem;
  height: 1rem;
  background-color: rgb(212, 212, 212);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.selectedMarker {
  composes: marker;
  background-color: var(--blue);
}

.hidden {
  opacity: 0;
}

.navigateButtonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  flex: 1;
}

.navigateButtonContainer > p {
  transition: 0.2s all;
}

.navigateButtonContainer > p:hover {
  text-decoration: underline;
  color: var(--blue);
}

.navigateButtonContainer > p:first-child {
  margin-left: auto;
}
