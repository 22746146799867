.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(75, 75, 75);
  z-index: calc(var(--sideBarZIndex) + 1);
}

.container video {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
}

.hidden {
  display: none;
}

.nameOverlay {
  position: absolute;
  height: 2rem;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
}

.nameDisplay {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1rem;
}

.nameDisplay i {
  margin-left: 1rem;
}

.openTicketContainer {
  position: fixed;
  top: 1rem;
  right: 0.5rem;
}
