@import "../../global-styles/variables";

.statusCont {
  display: flex;
  user-select: none;
  margin-bottom: -0.3rem;
}

.tooltip {
  width: max-content !important;
}

.statusCont i {
  margin-left: -8px;
  font-size: 1.4rem;

  @media screen and (max-width: $mobile-cutoff) {
    font-size: 1.2rem;
    margin-left: -5px;
  }
}

.grey {
  color: rgb(167, 167, 167);
}

.orange {
  color: orange;
}

.blue {
  color: var(--blue);
}
