@import "../../global-styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.inner {
  margin: auto;
  display: flex;
  @include mobile {
    flex-direction: column;
  }
}

.title {
  margin-bottom: 1rem;
}

.patientContainer {
  margin-right: 3rem;

  @include mobile {
    width: 100%;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patientContainerInner > div {
  border: 2px solid #e5e5e5;
  margin-top: 0.5rem;
  width: 30rem;
}

.parentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parentContainerInner {
  margin-top: 0.5rem;
  border: 2px solid #e5e5e5;
  width: fit-content;

  @include mobile {
    width: 100%;
    margin-top: 2rem;
  }
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}

.backButtonContainer {
  position: fixed;

  @include mobile {
    display: none;
  }
}
