@import "../../global-styles/variables";

.container {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: $mobile-cutoff) {
    width: 100%;
  }
}

.button {
  align-self: center;
  height: 3rem;
  border-radius: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5rem;
}

.text {
  max-width: 55rem;
  align-self: center;
  text-align: center;
  margin-top: 2rem;
  width: 100%;
}
