.row {
  display: flex;
  font-size: 1rem;
  padding: 1.1rem 1.5rem;
  background-color: white;
  align-items: center;
}

.row > p {
  width: 14rem;
  flex-shrink: 0;
}

.row p:first-child {
  font-weight: bold;
}

.white {
  background-color: white;
}

.blue {
  background-color: #47ceff1f;
}
