.note {
  font-size: 0.8rem;
  color: gray;
}

.finalNotes {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: rgb(73, 73, 73);
  height: 100%;
}

.finalNotes > p:first-child {
  margin-bottom: 0.75rem;
}

.uploadTitle {
  font-weight: bold;
  margin-top: 1rem;
}
