@import "../../../global-styles/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  flex: 1;
  font-size: 1.2rem;
  height: 4rem;
  cursor: pointer;
  user-select: none;
  position: relative;

  > div {
    margin-right: 3px;
  }

  @include mobile {
    font-size: 12px;
    height: 3rem;
    @media screen and (max-width: 410px) {
      font-size: 11px;
    }
    @media screen and (max-width: 350px) {
      font-size: 9px;
    }
    font-weight: normal;
    width: auto;
  }
}
