@import "../FirstFormSlide/FirstFormSlide.module.scss";
@import "../FifthFormSlide/FifthFormSlide.module.scss";
@import "../FourthFormSlide/FourthFormSlide.module.scss";

.formSlide {
  @include formSlide;
}

.cont {
  composes: appear from "../../../../global-styles/index.scss";
  margin-top: 1rem;
}

.addButton {
  @include addButton;
}

.nameDelCont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.deleteButton {
  @include deleteButton;
}

.showIllnessButton {
  composes: appear from "../../../../global-styles/index.scss";
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2rem;
  color: white;
  background-color: var(--blue);
  font-size: 1.4;
  line-height: 2rem;
  border-radius: 10px;
  margin: 1rem 0 0.2rem 0;
}

.top {
  margin-bottom: 1rem;
}
