@import "../FirstFormSlide/FirstFormSlide.module.scss";

.formSlide {
  @include formSlide;
}

.fieldsCont {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: rgb(192, 192, 192) solid 1px;
}

.dateCont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.dateCont > *:first-child {
  width: 7rem;
  margin-right: 1rem;
}

.dateCont i {
  align-self: center;
  margin-left: 1rem;
}

@mixin addButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.addButton {
  @include addButton();
}

.fileUploadContainer {
  margin-top: 2rem;
}

.fileUploadContainer p {
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}
