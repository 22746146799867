@import "../../global-styles/variables";

.button {
  display: flex;
  justify-content: center;
  height: min-content;
  align-items: center;
  border-radius: 1.5rem;
  height: var(--genericButtonHeight);
  font-size: 1rem;
  padding: 0 1.3rem 0 1.3rem;
  width: max-content;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  outline: none;
  position: relative;
  flex-shrink: 0;
  font-weight: bold;

  @media screen and (max-width: $mobile-cutoff) {
    padding: 0 1rem 0 1rem;
  }
}

.button p {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  height: min-content;
}

.blue {
  color: white;
  background-color: var(--blue);
}

.blue:hover {
  opacity: 0.7;
}

.blue:active {
  opacity: 0.5;
}

.white {
  color: rgb(12, 12, 12);
  background-color: white;
}

.grey {
  color: black;
  background-color: var(--blueBackground);
}

.grey:hover {
  opacity: 0.7;
}

.grey:active {
  opacity: 0.5;
}

.whiteBlue {
  color: var(--blue);
  border: solid var(--blue) 1px;
  font-weight: bold;
  background-color: white;
}

.whiteBlue:hover {
  background-color: var(--blue);
  color: white;
}

.whiteBlue:active {
  opacity: 0.7;
}

.red {
  background-color: var(--red);
  color: white;
}

.red:hover {
  opacity: 0.7;
}

.red:active {
  opacity: 0.5;
}

.hidden {
  visibility: hidden;
}

.loaderContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
