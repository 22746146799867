.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  .preview {
    display: flex;
    flex-direction: column;

    .intervalChooser {
      margin-top: 1rem;
    }
  }

  .dataContainer {
    display: flex;
    flex-direction: column;
    & > * {
      margin-right: 3rem;
      &:last-child {
        margin-right: 0;
      }
    }

    .fileContainer {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

    .documentDownloadLink {
      color: black;
      background-color: var(--blueBackground);
      padding: 0.5rem 1rem;
      border-radius: 1.5rem;
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;
      transition: opacity 100ms ease-in-out;
      &:hover {
        opacity: 0.7;
      }
      i {
        font-size: 1.5rem;
        margin-right: 0.25rem;
      }
    }
  }
}

.tooltip {
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.55rem;
  padding: 0.2rem 0.1rem;
  font-weight: 500;
  margin: 0;
}
