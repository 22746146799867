.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 15rem;
  width: 30rem;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  position: fixed;
  top: 50vh;
  left: calc((100vw - var(--sideBarWidth)) / 2 + var(--sideBarWidth));
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}

.container h4 {
  width: 100%;
  font-size: 1.5rem;
  border-bottom: gray solid 1px;
  margin-bottom: 4rem;
}

.button {
  margin-top: auto;
}
