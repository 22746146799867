@import "../../global-styles/variables";

.container {
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
  }
}

.topContainer {
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
}

.datePickerContainer {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1rem;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  box-shadow: -1px 2px 2px 2px rgb(219, 219, 219);
}

.hidden {
  opacity: 0;
}

.showCalendarContainer {
  display: flex;
  align-items: center;
  height: min-content;
  margin-top: 2rem;
}

.showCalendarContainer > *:first-child {
  margin-right: 2rem;
}
