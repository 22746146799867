@import "src/global-styles/variables";

.container {
  user-select: none;
  border-radius: 10px;
  overflow: hidden;
  width: fit-content;
  padding: 3rem;
  background-color: white;
  margin: 0 0;
  box-shadow: 0 0.4rem 0 1px var(--grey);
  flex-shrink: 0;
}

@include mobile {
  .container {
    width: 100%;
    padding: 1rem;
  }
}
