@import "../../../../global-styles/variables";

.container {
  display: flex;
  margin: 1rem 0 2rem 0;

  @include mobile {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.container > * {
  @include desktop {
    margin-right: 2rem;
  }

  @include mobile {
    width: 40%;
    margin-bottom: 5px;
  }
}

.container > *:last-child {
  margin-right: 0;
}
