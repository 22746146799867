.title {
  padding: 0 0;
}

.title > h1 {
  margin: 0;
  margin-bottom: 0.3rem;
}

.title > h2 {
  font-weight: 200;
  opacity: 0.66;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.nameContainer > *:first-child {
  margin-right: 1rem;
}

.fieldContainer {
  margin: 1rem 0 1rem 0;
}

.dateContainer {
  /* 	composes: date from "../NewPatientPage/NewPatientForm/FirsFormSlide/FirstFormSlide.module.scss"; */
}
