@import "../../global-styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-width: 40rem;

  > * {
    margin-bottom: 1rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  > div h2 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
}

.tableDataContainer {
  composes: tableDataContainer from "../OutpatientSheetDataTable/OutpatientSheetDataTable.module.scss";
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
}

.tableDataRow:nth-child(even) {
  background-color: #f5f5f5;
}

.tableDataRow p:first-child {
  width: 10rem;
}

.tableDataRow {
  composes: tableDataRow from "../OutpatientSheetDataTable/OutpatientSheetDataTable.module.scss";
}

.tableDataRow:hover {
  opacity: 0.7;
}

.tableDataRow:active {
  transform: translateY(-0.05rem);
}

.tableData {
  font-weight: 100;
}
