@import "../../global-styles/variables";

.container {
  z-index: 50;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.548);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    width: calc(100% - var(--sideBarWidth));
    left: var(--sideBarWidth);
  }

  @include mobile {
    height: calc(100% - var(--navBarHeight));
    width: 100%;
    top: var(--navBarHeight);
  }
}

.img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  height: auto;
}

.imageContainer {
  height: 90%;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.name {
  position: absolute;
  top: 0.6rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 10;

  @include mobile {
    position: fixed;
    left: 1rem;
    top: calc(var(--navBarHeight) + 1rem);
    max-width: calc(100% - 9rem);
  }
}

.download {
  text-decoration: none;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  @include mobile {
    position: fixed;
    top: calc(var(--navBarHeight) + 1rem);
    right: 1rem;
  }
}

.download i {
  font-size: 2rem;
  margin-left: 0.5rem;
}
