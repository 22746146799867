.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 2rem 0 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.096);
  cursor: pointer;
  color: black;
  background-color: white;
  outline: none;
  transition: opacity 0.1s ease-in-out;
}

.button:hover {
  opacity: 0.7;
}
