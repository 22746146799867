@import "../NewPatientPage/NewPatientForm/FirstFormSlide/FirstFormSlide.module.scss";
@import "../../global-styles/variables";

.card {
  @include formSlide;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: visible;

  @include mobile {
    padding-bottom: 50px;
    box-sizing: border-box;
  }
}

.spaceBetween {
  justify-content: space-between;
}

.gap > * {
  margin-bottom: 0.6rem;
}

.gap > *:last-child {
  margin-bottom: 0;
}

.card h3 {
  padding-bottom: 1rem;
}

.subtitle {
  color: grey;
  font-size: 0.9rem;
  margin-top: -2rem;
}

.buttonCont {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  margin-top: 1rem;

  @include mobile {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 50px;
    align-items: center;
    padding: 0 0.5rem;
  }
}

.marginTop {
  margin-top: auto;
}
