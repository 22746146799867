@import "../../../global-styles/variables";

.finisherContainer {
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: white;
  border-radius: 15px;
  border: solid 1px var(--blue);
  padding: 1rem;

  @include desktop {
    position: absolute;
    top: -10rem;
    right: -5rem;
    width: 20rem;
    min-width: max-content;
  }

  @include mobile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    box-sizing: border-box;
  }
}

.header {
  --color: gray;
  position: relative;
  border-bottom: 1px solid #dadada;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--color);
}

.header i {
  color: var(--color);
  position: absolute;
  right: 0;
  cursor: pointer;
}
