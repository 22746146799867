.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.8rem;
  padding-left: var(--iconWidth);
  height: min-content;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: var(--blue);
  color: white;
  box-sizing: border-box;
}

.title i {
  cursor: pointer;
}
