.textareaContainer {
  height: 14rem;
}

.dateTitle {
  margin: 0;
}

.rowflex {
  display: flex;
  align-items: center;
}

.rowflex > *:last-child {
  margin-left: auto;
}
