.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.6rem;
  color: rgb(94, 94, 94);
  height: var(--textInputHeight);
  margin-bottom: calc(0.75rem + 3px);
}
