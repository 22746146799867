.radioOptions {
  display: flex;
  width: 100%;
  font-size: 1em;
  align-items: center;
}

.verticalRadioOptions {
  composes: radioOptions;
  flex-direction: column;
}

.verticalRadioOptions > * {
  margin-bottom: 1rem;
}

.optionCont {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.wideOptionCont {
  composes: optionCont;
  width: 100%;
  justify-content: space-between;
  margin-right: 0;
}

.optionCont div {
  content: " ";
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
  margin-left: 0.5em;
  box-sizing: border-box;
  transition: all 0.1s linear;
  border-radius: 2px;
}

.optionCont div i {
  z-index: 1;
  font-size: 1.25em;
  color: white;
}

.unchecked {
  display: inline-block;
  border: 1px solid gray;
  background-color: white;
}

.checked {
  background-color: var(--blue);
  border-color: var(--blue);
}
