@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");

$mobile-cutoff: 800px; /*this should be kept in sync with variables.scss/$mobile-cutoff and css variable --mobileCutoff, cant import variable because of reasons*/

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  outline: none;
  height: 90% !important;
}

.appear {
  animation: appear 0.3s ease-in;
}

i {
  user-select: none;
}

* {
  border: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", "sans-serif";
}

/*******GlobalVariables********/
body {
  --blue: #47cdff;
  --blueDark: #01afee;
  --blueOpac: #47ceff44;
  --blueBackground: rgb(241, 242, 242);
  --blueShadow: #47ceff36;
  --green: #7be27b48;
  --greenDark: #1a521a;
  --red: rgba(231, 56, 2, 0.719);
  --redDark: #af0000;
  --redLight: #ffb6b19f;
  --errorRed: #f44336;
  --grey: #e6e7e8;
  --cardShadow: 3px -1px 2px 1px rgba(128, 128, 128, 0.219);
  --cardHeight: 36rem;
  --errorBackgroundColor: rgba(255, 0, 0, 0.062);
  --textInputPadding: 5px;
  --drHomePaddingLeft: 10vw;
  --borderRadius: 10px;
  --borderRadiusBig: 15px;
  --sideBarButtonWidth: calc(var(--sideBarWidth) * 0.75);
  --textInputHeight: 2.5rem;
  --sideBarZIndex: 60;
  --navBarHeight: 3.5rem;
  --mobileCutoff: 800px; /* this can be accessed from react (use the provided isMobile hook), the reason we also have to use scss because css variables cannot be used in media queries */

  @media screen and (min-width: $mobile-cutoff) {
    --genericButtonHeight: 2.5rem;
    --sideBarWidth: max(20rem, min(20vw, 24rem));
  }
  @media screen and (max-width: $mobile-cutoff) {
    --genericButtonHeight: 2.25rem;
    --sideBarWidth: 20rem;
  }
}
/***********************/

html,
body,
#root {
  overflow: hidden;
  height: 100%;
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1301px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-height: 780px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: $mobile-cutoff) {
  html {
    height: 100%;
    font-size: 15px;
  }
}

.hidden {
  opacity: 0;
}

@media screen and (min-width: $mobile-cutoff) {
  h5 {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

@media screen and (max-width: $mobile-cutoff) {
  h5 {
    font-size: 1rem;
  }
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (min-width: $mobile-cutoff) {
  h3 {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

@media screen and (max-width: $mobile-cutoff) {
  h3 {
    font-size: 1.2rem;
  }
}

li {
  list-style-position: inside;
}

.midsizedGreyText {
  font-size: 0.8rem;
  color: rgb(112, 112, 112);
}

.disabled {
  opacity: 0.6 !important;
}

::selection {
  background-color: var(--blue);
  color: white;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  background-color: rgba(255, 255, 255, 0) !important;
}

button {
  outline: none;
}

.linkText {
  color: var(--blue);
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  user-select: none;
}

.linkText:hover {
  opacity: 0.8;
}

.small-info-text {
  font-size: 0.75rem;
  color: gray;
}
