@import "../../global-styles/variables";

.container {
  --horizontalMarginPageBase: 2rem;
  --verticalMarginPageBase: 1rem;
  margin-left: var(--sideBarWidth);
  padding: var(--verticalMarginPageBase) var(--horizontalMarginPageBase);
  width: calc(100% - var(--sideBarWidth));
  min-width: fit-content;
  height: 100%;
  background-color: var(--blueBackground);
  box-sizing: border-box;
  z-index: 1;
  overflow: auto;
  position: relative;

  @media screen and (max-width: $mobile-cutoff) {
    margin-left: 0;
    width: 100%;
    padding-top: calc(var(--navBarHeight) + 1rem);
    min-width: 0;
    --horizontalMarginPageBase: 0.5rem;
  }
}
