@import "../../global-styles/variables";

.patientBlocksContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: -1rem;
  z-index: 0;
}

.patientBlocksContainer > * {
  margin-bottom: 1.5rem;
}

.patientBlocksContainer h3 {
  margin-bottom: 1rem;
}

.buttonsContainer {
  display: flex;
  margin: 0 auto;
  width: fit-content;

  @include mobile {
    flex-direction: column;
    > * {
      margin-bottom: 1rem;
      width: 15rem;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include desktop {
    > * {
      margin-right: 2rem;
    }
  }
}
