@import "../../global-styles/variables";

.containerBase {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileFullScreenContainer {
  composes: containerBase;
  height: 100%;
  width: 100%;
}

.container {
  composes: containerBase;

  @include desktop {
    margin-left: var(--sideBarWidth);
    width: calc(100vw - var(--sideBarWidth));
    transition: background-color 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    height: 100vh;
  }

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.containerFullscreen {
  composes: containerBase;
  right: 0;
  bottom: 0;
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
}

.containerFullscreen.visible {
  background-color: rgba(0, 0, 0, 0.25);
}

.container.visible {
  background-color: rgba(0, 0, 0, 0.25);
}

.panel {
  background-color: white;
  padding: 1rem;

  @include desktop {
    min-width: 35rem;
    max-height: 80vh;
    overflow-y: scroll;
    border-radius: 1rem;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.25);
  }

  @include mobile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.panel::-webkit-scrollbar {
  display: none;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.66rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgb(220, 220, 220);
}

.panel h1 {
  font-size: 1.5rem;
}

.top i {
  cursor: pointer;
  font-size: 2rem;
  transition: opacity 0.1s ease-in-out;
}

.top i:hover {
  opacity: 0.7;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -1rem;

  @include mobile {
    margin-top: auto;
    > * {
      width: 9rem !important;
    }
  }
}

.buttonContainer > * {
  margin-left: 1rem; /*flex gap equivalent for safari*/
}

.popupContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 35rem;
  text-align: center;

  @include mobile {
    margin-top: 30%;
  }
}

.docsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}
