@import "../../global-styles/variables";

.container {
  position: fixed;
  --rowGap: 2rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 3px 0 rgb(206, 206, 206);
  height: 100%;
  font-size: 1rem;
  align-items: center;
  padding: 0 3rem 0 3rem;
  width: var(--sideBarWidth);
  font-weight: bolder;
  box-sizing: border-box;
  --fadeOutTime: 0.3s;
  z-index: var(--sideBarZIndex);
  transition: 0.4s transform ease-in-out;
  top: 0;

  @media screen and (min-width: $mobile-cutoff) {
    left: 0;
  }

  @media screen and (max-width: $mobile-cutoff) {
    --rowGap: 1.5rem;
    top: var(--navBarHeight);
    height: calc(100% - var(--navBarHeight));
    right: 0;
    padding: 1rem;
  }

  > * {
    margin-bottom: var(--rowGap);
  }
  > *:last-child {
    margin-bottom: 1rem;
  }

  img {
    @media screen and (max-width: $mobile-cutoff) {
      display: none;
    }
    margin-top: 2rem;
    height: 3.6rem;
    width: auto;
    justify-self: center;
  }
}

.hiddenContainer {
  composes: container;
  @media screen and(max-width: $mobile-cutoff) {
    transform: translateX(100%);
  }
}

.unhiddenContainer {
  composes: container;
  @media screen and(max-width: $mobile-cutoff) {
    transform: translateX(0%);
    overflow-y: scroll;
  }
}

.texthr {
  box-sizing: border-box;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  width: 100%;
  overflow: visible;
  margin-bottom: -1em;

  &::before {
    content: "";
    background-color: #e6e7e8;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &::after {
    content: attr(content-text);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: #fcfcfa;
  }
}

.bottomPart {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 3em;
  transition: opacity var(--fadeOutTime) ease-in-out;
  margin-top: auto;

  > * {
    margin-bottom: var(--rowGap);
  }
  > *:last-child {
    margin-bottom: 1rem;
  }
}

.notificationContainer {
  height: 100%;
  overflow: hidden;
}
