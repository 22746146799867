.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container i {
  color: white;
}

.container p {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}
