.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  > .block:last-child {
    margin-bottom: 0;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
}

.dataContainer > * {
  margin-right: 3rem;
}

.dataContainer > *:last-child {
  margin-right: 0;
}

.fileContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.block {
  margin-bottom: 1rem;

  p {
    margin-top: 0.3rem;
  }
}
