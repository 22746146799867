.nameContainer {
  display: flex;
  justify-content: space-between;
}

.nameContainer > *:first-child {
  margin-right: 1rem;
}

.horizontalContainer {
  display: flex;
  justify-content: space-between;
}

.horizontalContainer > *:first-child {
  width: 9rem;
  margin-right: 1rem;
}

.nameTitle {
  width: 10rem;
  margin-right: 10%;
}

.dateContainer {
  /* 	composes: date from "../../user-components/NewPatientPage/NewPatientForm/FirstFormSlide/FirstFormSlide.module.scss"; */
}
