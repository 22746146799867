.container {
  display: flex;
  align-items: center;
}

.container input {
  border-bottom: solid 1px grey;
  font-size: 1.3rem;
  background-color: rgba(0, 0, 0, 0);
  width: 15rem;
  outline: none;
  margin-left: 0.5rem;
}

.container input::placeholder {
  color: rgb(160, 160, 160);
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container i.clear {
  cursor: pointer;
  transition: opacity 100ms ease-in-out;
  animation-name: appear;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.15s;
}

.container i.clear:hover {
  opacity: 0.8;
}

.container i.clear:active {
  opacity: 0.6;
}