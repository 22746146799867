@import "../../global-styles/_variables";

.container {
  @media screen and (min-width: $mobile-cutoff) {
    display: none;
  }
  width: 100%;
  height: var(--navBarHeight);
  background-color: rgba(255, 255, 255, 0.836);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(2px);
  display: flex;
  box-shadow: 2px 0 3px 5px rgba(0, 0, 0, 0.068);
  align-items: center;

  img {
    height: 2.1rem;
    width: auto;
  }
}

.notificationCircle {
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 15px;
  height: 15px;
}

.backButton {
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.menuButton {
  margin-left: auto;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
}
