.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--blueBackground);
}

.thankMsg {
  margin-bottom: 4rem;
}

.checkingMsg {
  margin-bottom: 2rem;
}
