@import "../../global-styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 1rem;
  box-sizing: border-box;
}

.bottomPart {
  display: flex;
  justify-content: space-between;
}

.tasksContainer {
  margin-right: 3rem;

  @include mobile {
    width: 100%;
    margin-right: 0;
  }
}

.tasksContainer > div:last-child {
  margin-bottom: 0;
}

.messagesContainer {
  position: sticky;
  bottom: var(--verticalMarginPageBase);
  height: fit-content;
  margin-top: auto;
}
