@import "../FirstFormSlide/FirstFormSlide.module.scss";

.formSlide {
  @include formSlide;
}

.address {
  display: flex;
  justify-content: space-between;
}

.address > *:first-child {
  margin-right: 1rem;
}
