.container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - var(--sideBarWidth));
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-sizing: border-box;
}

.buttonCont {
  display: flex;
  justify-content: space-between;
  width: 28rem; /*width of formDataBlock (2*rowwidth)*/
  padding: 1rem;
}

.popupContent {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 35rem;
}

.note {
  margin-top: 2rem;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
