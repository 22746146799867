@import "../../../global-styles/variables";
.container {
  composes: block-after from "../../../global-styles/block-visuals.module.css";
  width: 100%;
  background-color: white;
  border-radius: var(--borderRadiusBig);
  min-height: 44vh;
  display: flex;
  padding: 1.5rem 1rem 1.5rem 1rem;
  box-sizing: border-box;

  @include mobile {
    padding-bottom: 1rem;
    min-height: 0;
  }
}

.containerLeft {
  display: flex;
  flex-flow: column;

  @include mobile {
    flex: 1;
  }
}

.contentContainer {
  margin-left: 3.4rem;
  display: flex;
  flex-flow: column;
  > * {
    margin-bottom: 1rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    margin-left: 0;
  }
}

.symptomLabelContainer {
  display: flex;
  margin-left: -0.5rem;
  flex-wrap: wrap;

  .symptomLabel {
    position: relative;
    min-width: max-content;
    font-size: 0.8rem;
    height: 1.6rem;
    padding: 0 1rem 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    background-color: var(--blueBackground);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.containerRight {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  margin-left: auto;
  flex-shrink: 0;
  > * {
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-top: 1rem;
  }

  > *:first-child {
    margin-top: 0;
  }

  .newTicketButton {
    margin-bottom: 0;
  }

  > *:last-child {
    margin-right: 0;
  }

  @include mobile {
    display: none;
  }
}

.openTicketMenuButton {
  width: 100%;
  margin-top: 2rem;
}

.mobileMenuContainer {
  margin: 0 auto;
  > *:first-child {
    margin-top: 1rem;
  }
  > * {
    margin-bottom: 1rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
