$mobile-cutoff: 800px; /* this should be kept in sync with index.scss/mobile-cutoff */

@mixin mobile {
  @media screen and (max-width: $mobile-cutoff) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $mobile-cutoff) {
    @content;
  }
}
