@import "../../../NewPatientPage/NewPatientForm/SixthFormSlide/DiseaseField.module.scss";

.link {
  color: rgb(82, 82, 82);
  font-size: 0.95rem;
  cursor: pointer;
  padding-bottom: 1rem;
  padding-top: 1rem;
  user-select: none;
  display: flex;
  align-items: center;
}

.link i {
  margin-right: 0.6rem;
  margin-bottom: 2px;
}

.link:hover span {
  color: black;
  text-decoration: underline;
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: -0.5rem;
}

.formContainer > div:first-child {
  display: flex;
  align-items: flex-end;
}

.medicationContainer {
  margin-top: 0.6rem;
}

.amountCont {
  @include amountCont;
  margin-bottom: -2rem;
}

.medTitle {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
