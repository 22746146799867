@import "../../../../global-styles/variables";

@mixin formSlide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 2rem 3rem 2rem 3rem;
  border-radius: 20px;
  width: 20rem;
  box-shadow: var(--cardShadow);
  height: var(--cardHeight);

  @media screen and (max-width: $mobile-cutoff) {
    height: 100%;
    width: 100%;
    border-radius: 0;
    box-sizing: border-box;
    padding: 2rem 1.5rem 1rem 1.5rem;
  }
}

.formSlide {
  @include formSlide();
}

.names {
  display: flex;
  justify-content: space-between;
}

.names div:first-child {
  margin-right: 0.5rem;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.date > div {
  margin-right: 1.5rem;
}

.date > div:last-child {
  margin-right: 0;
}

.date > div:first-child {
  width: 100%;
  min-width: 6rem;
  flex-shrink: 3;
}

.date > div:nth-child(2) {
  width: 100%;
  min-width: 6rem;
}

.date > div:nth-child(3) {
  width: 100%;
  min-width: 3rem;
  flex-shrink: 3;
}
