.popupContent {
  text-align: left;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.popupContent p {
  margin-bottom: 0.5rem;
}

.popupContent .errorReferenceText {
  margin-top: 1rem;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
}

.popupContent .errorReferenceText:active {
  opacity: 0.7;
}

.popupContent .errorReferenceCode {
  font-weight: 700;
  background-color: var(--grey);
  height: 2rem;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  color: var(--errorRed);
}

.switcheroo {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: var(--sideBarWidth);
  height: 100%;
  width: calc(100vw - var(--sideBarWidth));
  background-color: rgba(0, 0, 0, 0.726);
}

.lansdcapeOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.lansdcapeOverlay h3 {
  margin-top: 1rem;
}

.lansdcapeOverlay i {
  font-size: 3rem;
}
