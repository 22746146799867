.container {
  height: 100%;
  margin-top: 4rem;
}

.container > * {
  margin-bottom: 2rem;
}

.container strong {
  color: var(--blue);
  cursor: pointer;
}
