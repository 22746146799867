.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-bottom: 1rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
