@import "../../../global-styles/variables";

.videoConsultationPopupTextContainer {
  text-align: center;
  width: 100%;
  margin: 0 !important;

  @include desktop {
    width: 35rem;
  }
}

.popupButton {
  width: 9rem;
}
