@import "../../global-styles/variables";

.inputContainer {
  display: flex;
  width: 28rem;
  flex-direction: column;
  margin: 0 auto 0 auto;

  @include mobile {
    width: 100%;
  }
}

.inputContainer > p {
  margin-bottom: 3rem;
}

.inputContainer > div {
  width: 70%;
  margin: 0 auto 0 auto;

  @include mobile {
    width: 100%;
  }
}
