@import "../../global-styles/variables";

.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: var(--blueBackground);
  height: 3.2rem;
  align-items: center;
  border-radius: 1.6rem;
  padding: 0 1.5rem 0 1.5rem;
  width: var(--sideBarButtonWidth);
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  font-weight: bold;
  flex-shrink: 0;
  transition: opacity 0.1s ease-in-out;

  @include mobile {
    height: 2.75rem;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.6;
  }

  i {
    justify-self: end;
    font-size: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;

    @include mobile {
      font-size: 1.6rem;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  p {
    justify-self: start;
  }

  .disabled {
    opacity: 0.6;
  }

  .container.disabled:hover {
    opacity: 0.6;
  }
}

.loaderContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.hidden {
  visibility: hidden;
}
