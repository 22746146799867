@import "../../global-styles/variables";

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: fit-content;

  @media screen and (max-width: $mobile-cutoff) {
    position: fixed;
    top: var(--navBarHeight);
    left: 0;
    height: calc(100% - var(--navBarHeight));
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.child {
  grid-column: 1;
  grid-row: 1;
  transition: transform 0.8s ease-out;
  overflow-x: visible;

  @media screen and (max-width: $mobile-cutoff) {
    width: 100%;
    min-height: 100%;
    transition: transform 0.6s ease-out;
  }
}
