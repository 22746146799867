@import "../../../global-styles/variables";

.fileUploadButtonContainer {
  @include mobile {
    margin-top: 1rem;
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.flexRow > *:first-child {
  margin-right: 2rem;
}
