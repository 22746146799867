.label {
  background-color: var(--blueBackground);
  font-size: 0.9rem;
  height: min-content;
  padding: 3px 0.6rem 3px 0.4rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label i {
  margin-left: 0.2rem;
  font-size: 1.2rem;
  cursor: pointer;
}
