.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  > .checkbox {
    margin-bottom: 0.5rem;
  }

  i {
    margin-right: 0.5rem;
    font-size: 1.3rem;
  }

  input[type="file"] {
    display: none;
  }

  .noteInput {
    width: 100%;
  }
}

.peDateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .peDateLabel {
    color: gray;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.uploadContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: column;

  i {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    .button {
      background-color: var(--blueBackground);
      padding: 0 1rem 0 1rem;
      height: 2rem;
      border-radius: 15px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: min-content;
      transition: opacity 100ms ease-in-out;
      white-space: nowrap;
      &.highlighted {
        background-color: var(--blue);
        color: white;
        font-weight: 700;
      }
      &.warning {
        background-color: #ffc918;
        padding: 15px;
        cursor: default;
        &:hover {
          opacity: 1;
        }
      }
      &:hover {
        opacity: 0.7;
      }
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .fileUploadDisplay {
      color: black;
      background-color: var(--blueBackground);
      padding: 0.5rem 0.25rem 0.5rem 1rem;
      border-radius: 1.5rem;
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;

      i {
        transition: opacity 100ms ease-in-out;
        font-size: 1.5rem;
        margin-left: 0.5rem;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .documentDownloadLink {
    color: black;
    background-color: var(--blueBackground);
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    transition: opacity 100ms ease-in-out;
    &:hover {
      opacity: 0.7;
    }
    i {
      font-size: 1.5rem;
      margin-right: 0.25rem;
    }
  }
}

.container .uploadContainer span {
  margin-left: 2rem;
  max-width: 15rem;
}

.checkbox {
  width: max-content;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.horizontalBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  & > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.text {
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  margin-right: auto;
  color: gray;
}
