@import "../../global-styles/variables";

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--blueBackground);
  z-index: 1;
  overflow-y: auto;

  @include desktop {
    align-items: center;
    justify-content: center;
  }

  @include mobile {
    min-height: 0;

    .leftSide {
      display: none;
    }
  }

  .form {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    width: 38vw;
    height: 70vh;
    min-height: 45rem;
    max-width: 32rem;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    justify-self: end;
    margin-bottom: 50px;
    padding: 1rem 2rem 1rem 2rem;
    margin-right: 60px;

    @include mobile {
      width: 100%;
      min-height: 100%;
      height: fit-content;
      margin: 0;
      border-radius: 0;
    }

    h1 {
      margin-top: 1rem;
    }

    h1 p {
      display: inline;
      padding-left: 1rem;
      font-size: 0.8rem !important;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #e6e7e8;
      transform: translateY(30px) translateX(15px) rotate(2.5deg);
      content: "";
      z-index: -1;
      border-radius: 10px;

      @include mobile {
        display: none;
      }
    }
  }
}

.container img {
  height: 40px;
  width: auto;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.fieldContainer {
  margin: 1rem 0 1rem 0;
}

.nameContainer div {
  width: 45%;
}

.container h3 {
  font-size: 44px;
  font-weight: bold;
  margin-top: 10px;
}

.container h4 {
  font-size: 18px;
  font-weight: bolder;
  margin-top: 10px;
}

.container h5 {
  font-size: 18px;
  color: var(--blue);
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
}

.container > p {
  font-size: 1rem;
}

.button {
  height: 3rem !important;
  font-weight: bold;
  width: 7.5rem;
}

.buttonContainer {
  margin-top: 1rem;
  display: flex;
  margin-left: -1rem;

  @include mobile {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
}

.buttonContainer > * {
  margin-left: 1rem; /*flex gap equivalent for safari*/
}

.popupContent {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 35rem;
}
