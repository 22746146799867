.block-after {
  position: relative;
}

.block-after::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--grey);
  z-index: -1;
  transform: translateY(0.3em);
  border-radius: 0.7em;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.096);
}
