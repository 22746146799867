@import "src/global-styles/variables";

.container {
  composes: block-after from "src/global-styles/block-visuals.module.css";
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
  width: 55rem;
  flex-shrink: 0;
  img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    margin-right: 2rem;
    border-radius: 10px;
  }
}

.rightContainer {
  height: 95%;
  align-self: center;
  box-sizing: border-box;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleContainer i {
  margin-right: 0.3rem;
  color: rgb(175, 175, 175);
}

.keywords {
  color: grey;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

body {
  .tooltip {
    margin: 0;
    margin-bottom: 0.7rem;
    padding: 0.3rem;
    --color: rgb(75, 75, 75);
    font-size: 1rem;
    font-family: "Nunito Sans";
    background-color: var(--color);
  }
  .arrow {
    color: var(--color);
  }
}

@include mobile {
  .container {
    width: 100%;
    flex-direction: column;
    img {
      width: 100%;
      margin: 0 0;
      margin-bottom: 1rem;
    }
  }
}
