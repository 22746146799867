@import "../../global-styles/variables";

.icon {
  --iconSize: 1.7rem;
  background-color: #76c548;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  display: flex;
  align-items: center;
  color: white;
  width: var(--iconSize);
  height: var(--iconSize);
  border-radius: 5px;
  overflow: hidden;
}

.icon i {
  width: min-content;
  font-size: var(--iconSize);
}

.checked {
  background-color: var(--blue);
}

.rejected {
  background-color: var(--red);
}
