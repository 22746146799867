.container {
  display: flex;
  flex-flow: column;
}

.container > p {
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}

.calendarContainer {
  position: absolute;
  top: 10%;
  left: 110%;
  overflow: hidden;
}

.calendarContainer :global(.rdrCalendarWrapper) {
  border-radius: 15px;
  padding: 0;
}

.rowContainer {
  display: flex;
  margin-top: 0.6rem;
  align-items: center;
}

.rowContainer > *:first-child {
  margin-right: 1rem;
}
