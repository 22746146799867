@import "../../global-styles/variables";

.container {
  composes: block-after from "../../global-styles/block-visuals.module.css";
  display: flex;
  background-color: white;
  width: 45rem;
  justify-content: space-between;
  padding: 1.5rem 2rem 1.5rem 2rem;
  align-items: center;
  border-radius: 15px;

  @include mobile {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
  }
}

.buttonContainer {
  display: flex;

  @include desktop {
    > * {
      margin-left: 1rem;
    }
  }

  @include mobile {
    flex-direction: column;
    width: 12rem;
    flex-shrink: 0;

    > * {
      margin-bottom: 1rem;
      width: 100%;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.blockTitle {
  font-size: 1.2rem;
  font-weight: 700;
}

.popupContent {
  text-align: center;
  margin: 2rem 1rem;
}
