.dateChooserBlock {
  display: flex;
  align-items: center;
  position: relative;
  color: rgb(70, 70, 70);
  z-index: 1;
}

.dateChooserBlock > * {
  margin-right: 1rem;
}

.dateChooserBlock p {
  cursor: pointer;
}

.dateChooserBlock p:hover {
  text-decoration: underline;
}
