.datePickerContainer {
  position: absolute;
  height: min-content;
  top: 115%;
  left: 50%;
  transform: translateX(-50%) translateY(-12rem);
  border-radius: 15px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-shadow: 3px 3px 10px 4px rgb(235, 235, 235);
  box-sizing: border-box;
  z-index: 1;
}

.text {
  transition: all 0.2s;
}

.text:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(87, 87, 87);
}
