.checkboxCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s linear;
}

.topContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topContainer div {
  content: " ";
  height: 1.3rem;
  width: 1.3rem;
  cursor: pointer;
  margin-right: 0.5rem;
  box-sizing: border-box;
  border-radius: 2px;
  flex-shrink: 0;
}

.checkboxCont div i {
  z-index: 1;
  font-size: 1.25rem;
  color: white;
}

.error {
  font-size: 0.85rem;
  color: var(--errorRed);
  margin-left: auto;
}

.errorBox {
  --color: rgba(255, 0, 0, 0.267);
  background-color: var(--color);
  border-color: var(--color);
}

.checked {
  composes: checked from "../RadioButton/RadioButton.module.css";
}

.unchecked {
  composes: unchecked from "../RadioButton/RadioButton.module.css";
}

.disabled {
  opacity: 0.5;
}
