.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.settingsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  flex: 1;
}
