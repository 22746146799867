@import "../../global-styles/variables";

.container h6 {
  margin-bottom: 0.5rem;
}

.table {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  width: fit-content;

  @include mobile {
    width: 100%;
  }
}

.table .row:last-child {
  border-radius: 0 0 10px 10px;
}

.row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  border: 1px rgb(209, 209, 209) solid;
  border-top: none;

  @include mobile {
    width: 100%;
    box-sizing: border-box;
  }

  > * {
    margin-right: 2rem;

    @include mobile {
      margin-right: 1rem;
      flex: 1;
    }
  }

  > *:last-child {
    margin-right: 0;
  }

  > div:first-child {
    @include desktop {
      width: 12rem;
    }
  }

  > div:nth-child(2) {
    @include desktop {
      width: 8rem;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 5rem;
}

.header {
  background-color: var(--blue);
  color: white;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: none;
}
