@import "../FirstFormSlide/FirstFormSlide.module.scss";

.container {
  @include formSlide;
}

.personData {
  display: flex;
  justify-content: space-between;
}

.unit {
  height: 2.5rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0 5px 5px 0;
  border: solid gray 1px;
  border-left: none;
  align-self: flex-end;
  box-sizing: border-box;
}

.noBorderRadius {
  border-radius: 0 !important;
}

.dataandunit {
  width: 45%;
  display: flex;
}

.dataandunit div:first-child input {
  border-radius: 5px 0 0 5px;
}
