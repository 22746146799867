@import "../../../global-styles/variables";

.container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - var(--sideBarWidth));
  background-color: white;
  box-shadow: 0 4px 4px 0 rgb(211, 211, 211);
  z-index: 2;

  @include mobile {
    width: 100%;
    top: var(--navBarHeight);
  }
}

.firstButtonCont {
  position: relative;
  height: fit-content;
  flex: 1;
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 5px solid var(--blue);
  transition: transform 0.2s ease-in-out;
}

.first {
  transform: translateX(0);
}

.second {
  transform: translateX(100%);
}

.third {
  transform: translateX(200%);
}

.fourth {
  transform: translateX(300%);
}
