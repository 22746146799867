@import "../../../global-styles/variables";

.cont {
  --size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  border-radius: calc(var(--size) / 2);
  color: white;
  font-size: 1rem;
  margin-right: 0.1rem;

  @include mobile {
    --size: 1rem;
    font-weight: 700;
    font-size: 0.65rem;
    @media screen and (min-width: 375px) {
      --size: 1.4rem;
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
}
