.timePickerContainer {
  composes: datePickerContainer from "../DayChooser/DayChooser.module.css";
  box-shadow: none;
  z-index: 1;
}

.timePickerContainer :global(.MuiPickersClockPointer-thumb) {
  border-color: var(--blue);
}

.timePickerContainer :global(.MuiPickersClockPointer-noPoint) {
  background-color: var(--blue);
}

.timePickerContainer :global(.MuiPickersClock-pin) {
  background-color: var(--blue);
}

.timePickerContainer :global(.MuiPickersClockPointer-pointer) {
  background-color: var(--blue);
}

.timePickerContainer :global(.MuiPickersClock-clock) {
  background-color: var(--blueBackground);
  border: var(--blue) solid 1px;
}

.text {
  transition: all 0.2s;
}

.text:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(87, 87, 87);
}
