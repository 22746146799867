.flexRowCent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexColCent {
  composes: flexRowCent;
  flex-direction: column;
}
