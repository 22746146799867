.textarea {
  height: 100%;
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.container > * {
  margin-bottom: 1rem;
}

.container > *:last-child {
  margin-bottom: 0;
}
