.labelCont {
  display: flex;
  flex-wrap: wrap;
}

.labelCont > * {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.labelCont > *:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.button {
  align-self: center;
}
