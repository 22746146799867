@import "../../../global-styles/variables";

@mixin mobileMessagesContainer {
  position: fixed;
  top: var(--navBarHeight);
  left: 0;
  height: calc(100% - var(--navBarHeight));
  @include mobile {
    width: 100%;
  }
  transition: 0.4s all ease-in-out;
}

.messagesContainerOpen {
  @include desktop {
    position: sticky;
    margin-top: auto;
    bottom: var(--verticalMarginPageBase);
  }

  @include mobile {
    @include mobileMessagesContainer;
  }
}

.messagesContainerClosed {
  @include mobile {
    @include mobileMessagesContainer;
    top: 100%;
  }
}

.openMessagingButton {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  border-radius: 1.75rem;
  cursor: pointer;

  i {
    color: white;
    font-size: 1.6rem;
    margin-top: 0.2rem;
  }
}

.newMsgMarkerContainer {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
