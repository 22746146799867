@import "../../global-styles/variables";

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 4rem;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.tasksContainer {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  @include mobile {
    margin-right: 0;
    width: 100%;
  }
}

.tasksContainer > * {
  margin-bottom: 2rem;
}

.tasksContainer > *:last-child {
  margin-bottom: 0;
}
