@import "../../global-styles/variables";

.tableDataContainer {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

  @include desktop {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.tableDataRow:nth-child(even) {
  background-color: #f5f5f5;
}

.tableDataRow p:first-child {
  width: 13rem;
  flex-shrink: 0;
}

.tableDataRow {
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

.tableDataRow:hover {
  opacity: 0.7;
}

.tableDataRow:active {
  transform: translateY(-0.05rem);
}

.tableData {
  font-weight: 100;
}
