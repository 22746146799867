@import "../../../global-styles/variables";

.container {
  composes: block-after from "../../../global-styles/block-visuals.module.css";
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: white;
  padding: 2rem;
  padding-bottom: 1.5rem;
  height: max-content;
  box-sizing: border-box;
  border-top: 1px solid rgb(190, 190, 190);

  @media screen and (max-width: $mobile-cutoff) {
    padding: 1rem;
    padding-bottom: 0.5rem;
  }
}

.container:nth-last-child(1) {
  border-radius: 0 0 0.6rem 0.6rem;
}

.leftColumnContainer {
  display: flex;
  flex-flow: column;
}

.rightColumnContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.rightColumnContainer > * {
  margin-bottom: 1rem;
}

.rightColumnContainer > *:last-child {
  margin-bottom: 0;
}

.button {
  height: 2.2rem;
  width: 8rem;
}

.topLine {
  display: flex;
  align-items: center;
}

.topLine > * {
  margin-right: 1rem;
}

.topLine > *:last-child {
  margin-right: 0;
}

.dateCont {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.dateCont > * {
  margin-right: 0.3rem;
}

.dateCont > *:last-child {
  margin-right: 0;
}

.dateCont i {
  font-size: 1.2rem;
}

.labelCont {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;

  > * {
    margin-right: 0.5rem;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.label {
  display: flex;
  align-items: center;
  height: 1.4rem;
  font-size: 0.9rem;
  padding: 0 1rem 0 1rem;
  background-color: var(--blueBackground);
  border-radius: 0.7rem;
  margin-bottom: 0.5rem;
}

.mobileClose {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: rgb(61, 61, 61);
}
