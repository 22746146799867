$iconSize: 1.7rem;

.icon {
  background-color: #76c548;
  position: absolute;
  top: calc($iconSize * -0.3);
  right: calc($iconSize * -0.3);
  display: flex;
  align-items: center;
  color: white;
  width: $iconSize;
  height: $iconSize;
  border-radius: 5px;
  overflow: hidden; //because of some reason the icon still overflows the container
}

.icon i {
  width: $iconSize;
  font-size: $iconSize;
  margin: 0;
  padding: 0;
}

.checked {
  background-color: var(--blue);
}

.rejected {
  background-color: var(--red);
}

.acceptButton {
  width: 12.5rem;
}
