.container {
  display: flex;
  height: 100%;
  width: 100vw;
  align-items: center;
  flex-flow: column;
}

.container img {
  width: 70%;
  height: auto;
  margin-bottom: 30%;
  margin-top: 25%;
}

.container h4 {
  width: 85%;
  text-align: center;
}
