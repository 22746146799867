.profileContainer {
  margin-bottom: 2rem;
}

.profileContainer h1 {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}

.profileContainer h2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #555;
}

.buttonsContainer {
  display: flex;
}

.buttonsContainer > *:first-child {
  margin-right: 2rem;
}
