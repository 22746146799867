@import "../../../../global-styles/variables";

.container {
  min-width: fit-content;
  width: 100%;
  max-width: 70rem;
  margin-right: 3rem;
  margin-top: 3rem;
  z-index: 1;
}

.container:last-child {
  margin-bottom: 3rem;
}

.ticketContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 1em 2em 1em 2em;
  box-sizing: border-box;

  @include mobile {
    padding: 1rem;
  }
}

.topRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  .spacer {
    flex: 1;
  }

  h3 {
    white-space: nowrap;
    margin-right: 3rem;

    @include mobile {
      margin-right: 0;
      max-width: 70vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.topRow > *:last-child {
  margin-right: 0;
}

.labelsCont {
  display: flex;

  @include desktop {
    > * {
      margin-left: 4px;
    }
    > *:first-child {
      margin-left: 0;
    }
  }

  @include mobile {
    flex-wrap: wrap;
    margin-top: 10px;
    > * {
      margin-bottom: 6px;
      margin-right: 4px;
    }
  }
}

.ticketContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--grey);
  z-index: -1;
  transform: translateY(0.5em);
  border-radius: 0.5em;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.096);
}

.priorIcon {
  position: absolute;
  right: -0.3em;
  top: -0.3em;
  font-size: 36px;
  color: red;
  box-sizing: border-box;
}

.secondRow {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  color: rgb(150, 150, 150);
  align-items: center;

  > * {
    margin-right: 0.5rem;
  }

  @include mobile {
    margin-top: 2px;
    > i {
      font-size: 1.3rem;
    }
    > p {
      font-size: 0.9rem;
    }
  }
}

#lastRow {
  display: flex;
  margin-top: 0.5em;
  color: black;
  align-items: center;
  margin-top: 0.75rem;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .symptomList {
    max-width: #{"min(100%, 30rem)"};
  }
}

.buttonCont {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  @include mobile {
    margin-left: auto;
    margin-top: 5px;
  }
}

.buttonCont > * {
  margin-right: 2rem;
}

.buttonCont > *:last-child {
  margin-right: 0;
}
