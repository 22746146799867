.container {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
}

.container:last-child {
  border: none;
}

.container i {
  font-size: 0.6rem;
  color: orange;
}

.firstRow {
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  justify-content: space-between;
}

.secondRow {
  display: flex;
  font-size: 0.9rem;
  font-weight: normal;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
}

.time {
  font-size: 0.8rem;
  font-weight: normal;
  color: gray;
  width: max-content;
  margin-left: auto;
}
