@import "../FifthFormSlide/FifthFormSlide.module.scss";
@import "../FourthFormSlide/FourthFormSlide.module.scss";

.cont {
  margin-bottom: 1rem;
}

.dataCont {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: rgb(165, 165, 165) 1px solid;
  padding-bottom: 1rem;
}

@mixin amountCont {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  > *:nth-child(2) {
    margin-left: 1rem;
    width: 13.5rem;
  }
}

.amountCont {
  @include amountCont();
}

.addButton {
  @include addButton;
}

.deleteButton {
  @include deleteButton;
}

.nameDelCont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dose {
  margin-bottom: 1rem;
}

.fileUploadContainer p {
  font-size: 0.9rem;
}

.fileUploadContainer > p {
  margin-bottom: 0.6rem;
}
