@import "../../global-styles/variables";

$topRowHeight: 3rem;

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.7rem 0 1.7rem;
}

.innerContainer > * {
  border-bottom: rgb(192, 192, 192) solid 1px;
  margin-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.innerContainer > *:last-child {
  border-bottom: none;
}

.innerContainer > p {
  margin-top: calc(var(--height) / 2 * 0.75);
  font-weight: 100;
  color: #666;
}

.topRow {
  display: flex;
  height: $topRowHeight;
}

.topRow > *:first-child {
  margin-right: 1rem;
}

.topRow i {
  color: grey;
}

.placeholder {
  text-align: center;
}

.content {
  height: calc(100% - #{$topRowHeight});
}
