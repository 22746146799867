.title {
  display: flex;
  align-items: center;
}

.title i {
  font-size: 2rem;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 0.25rem;
}

.title h1 {
  font-size: 1.7rem;
  margin: 0;
}
