.container {
  display: flex;
  height: 3.5rem;
  width: 100vw;
  justify-content: center;
  --background: rgb(56, 56, 56);
  background-color: var(--background);
  margin-top: auto;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 5rem;
  cursor: pointer;
  background-color: var(--background);
  transition: all 0.3s;
}

.button:hover {
  background-color: rgb(88, 88, 88);
}

.button i {
  font-size: 2.5rem;
  color: white;
}

.endCall i {
  color: rgb(209, 33, 57);
}
