@import "../../global-styles/variables";

.openContainer {
  background-color: white;
  box-sizing: border-box;

  @include desktop {
    display: flex;
    flex-direction: column;
    --height: 28rem;
    height: var(--height);
    width: calc(1.33 * var(--sideBarButtonWidth));
    top: 0;
    left: calc(100% + (var(--sideBarWidth) - var(--sideBarButtonWidth)) / 2 + 1rem);
    position: absolute;
    border-radius: 15px;
    padding: 1rem 0 0.3rem 0;
    box-shadow: 1px 1px 2px 2px rgb(224, 224, 224);
    overflow: hidden;
  }

  @include mobile {
    position: fixed;
    top: var(--navBarHeight);
    right: 0;
    height: calc(100% - var(--navBarHeight));
    width: 100%;
    z-index: 100;
    transition: top 0.4s ease-in-out;
  }

  h1 {
    font-size: 1.2rem;
    text-align: left;
    margin-left: 1.7rem;
    margin-bottom: 0.5rem;
  }
}

.closedContainer {
  composes: openContainer;
  top: 100%;
}
