@import "../../../../global-styles/variables";

.label {
  position: relative;
  min-width: max-content;
  font-size: 0.8rem;
  height: 1.6rem;
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  background-color: var(--blueBackground);

  &.done {
    color: var(--greenDark);
    background-color: var(--green);
    &::after {
      content: '✔️';
      margin-left: 5px;
    }
  }

  &.rejected {
    color: var(--redDark);
    background-color: var(--redLight);
    &::after {
      content: '✕';
      margin-left: 5px;
    }
  }
}
