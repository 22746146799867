@import "../../global-styles/variables";

.container {
  composes: block-after from "../../global-styles/block-visuals.module.css";
  display: flex;
  width: 100%;
  min-width: fit-content;
  min-height: 45vh;
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 2rem;

  @include mobile {
    min-height: 0;
    min-width: 0;
  }
}

.leftContainer {
  display: flex;
  flex-direction: column;
  @include desktop {
    margin-right: 3rem;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 3.45rem;
  margin-top: 1rem;

  @include mobile {
    margin-left: 0;
  }
}

.contentContainer > * {
  margin-bottom: 1.3rem;
}

.contentContainer > *:last-child {
  margin-bottom: 0;
}

.rightContainer > * {
  margin-bottom: 2rem;
}

.rightContainer > *:last-child {
  margin-bottom: 0;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.diagnosisTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  width: fit-content;
}

.diagnosisTitleContainer i {
  vertical-align: middle;
  margin-bottom: 0.1rem;
}

.diagnosisContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 30rem;
}

.diagnosisContainer *:last-child {
  margin-right: 0;
}

.diagnosisItem {
  font-size: 0.9rem;
  border-radius: var(--borderRadiusBig);
  padding: 0.3rem 0.7rem;
  background-color: var(--blueBackground);
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
}

.showFullDiagnosisButton {
  cursor: pointer;
  margin-left: 0.5rem;
  background-color: var(--blueBackground);
  border-radius: var(--borderRadiusBig);
  padding: 0.3rem 1rem;
}

.showFullDiagnosisButton:hover {
  background-color: var(--grey);
}

.note {
  /* 	width: 10%; */
}
