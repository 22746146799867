@import "../FirstFormSlide/FirstFormSlide.module.scss";

.formSlide {
  @include formSlide;
  justify-content: flex-start;
}

.allergyCheckCont p {
  padding-bottom: 5px;
}

.buttonCont {
  width: min-content;
  align-self: center;
}

.inputCont {
  display: flex;
  justify-content: stretch;
  width: 100%;
  margin: 1rem 0 1rem 0;
  animation: var(--appear);
}

@mixin deleteButton {
  align-self: flex-end;
  padding-left: 0.4rem;
  cursor: pointer;
}

.deleteButton {
  @include deleteButton();
}
