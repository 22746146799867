@import "src/global-styles/variables";

.container {
  margin: 0 auto 0 auto;
  z-index: 0; /* this is needed in order to the block::after shadow to show above PageBaseView background */
  display: flex;
  flex-direction: column;
  max-width: 55rem;
  text-align: justify;
}

.titleContainer {
  display: flex;
  margin-top: 4rem;
  flex-direction: row;
  i {
    font-size: 2rem;
    margin-right: 0.3rem;
  }
}

.head {
  h2 {
    font-size: 1.8rem;
    margin-top: -0.1rem;
  }
  p {
    color: grey;
    margin-bottom: 2rem;
  }
}

.innerContainer {
  margin-bottom: 2.5rem;
  h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  .text {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  strong {
    font-size: 1.15rem;
  }
  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    font-size: 1.1rem;
  }
}

.mobilePdfButtonContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  h3 {
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-align: center;
    opacity: 0.6;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  a {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    text-decoration: none;
    font-weight: 700;
    background-color: white;
    border: 1px solid var(--blue);
    color: var(--blue);
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    flex: 1;
    min-width: fit-content;

    i {
      margin-right: 0.25rem;
      font-size: 1.3rem;
      opacity: 0.8;
    }
  }
}

.buttonContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-right: 1rem;
  }
  button:last-child {
    margin-right: 0;
  }
}

@include mobile {
  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .titleContainer {
    margin-top: 1rem;
  }
}
