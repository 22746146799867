.container {
  align-self: flex-start;
}

.container h2 {
  margin-bottom: 0.5rem;
}

.container p {
  color: #666;
}
