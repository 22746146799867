@import "../../global-styles/variables";

.profileContainer h1 {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;

  @include mobile {
    width: 100%;
  }
}

.profileContainer h2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #555;
}
