@import "../../../global-styles/variables";

.container {
  min-width: fit-content;
  width: 100%;
  max-width: 70rem;
  margin-right: 3rem;
  margin-top: 3rem;
  z-index: 1;

  @include mobile {
    min-width: 0;
  }
}

.ticketContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 1em 2em 1em 2em;
  box-sizing: border-box;
}

.topRow {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

@include desktop {
  .topRow > * {
    margin-right: 3rem;
  }
}

.topRow > *:last-child {
  margin-right: 0;
}

.labelsCont {
  display: flex;
  margin-bottom: 0.75rem;

  @include mobile {
    flex-wrap: wrap;
    margin: 0.5rem 0;

    > div {
      width: fit-content;
    }
  }
}

.ticketContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--grey);
  z-index: -1;
  transform: translateY(0.5em);
  border-radius: 0.5em;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.096);
}

.priorIcon {
  position: absolute;
  right: -0.3em;
  top: -0.3em;
  font-size: 36px;
  color: red;
  box-sizing: border-box;
}

#title {
  font-weight: bold;
  font-size: 1.4rem;

  @include mobile {
    font-size: 1.1rem;
  }
}

.secondRow {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  color: rgb(150, 150, 150);
  align-items: center;

  @include mobile {
    margin-top: 0.5rem;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(3, fit-content);
    row-gap: 0.5rem;
  }

  p {
    font-size: 0.85rem;
  }
}

.secondRow i {
  font-size: 1.3rem;
}

.secondRow > * {
  margin-right: 0.5rem;
}

#lastRow {
  display: flex;
  margin-top: 0.5em;
  color: black;
  align-items: center;
}

.buttonCont {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.buttonCont > * {
  margin-right: 2rem;
}

.buttonCont > *:last-child {
  margin-right: 0;
}

.buttonCont button {
  height: 2.5em;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  width: 8em;
  border-radius: 1.25em;
  font-weight: bold;
}
