.blueButton {
  background-color: var(--blue);
  color: white;
}

.lastButtonContainer {
  margin-top: auto !important;
}

.newTicketButton {
  position: relative;
}

.newTicketStartContainer {
  position: absolute;
  background-color: var(--blueBackground);
  display: flex;
  flex-direction: column;
  top: 0%;
  left: 0;
  transform: translateX(-110%);
  z-index: 1;
  padding: 1rem 2rem 0 2rem;
  border-radius: 15px;
}

.newTicketStartContainer form > * {
  margin-bottom: 1rem;
}
