.label {
  position: relative;
  width: 6rem;
  min-width: max-content;
  font-size: 0.8rem;
  height: 1.6rem;
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  background-color: var(--blueBackground);
  margin: 0 0 0 0.5rem;
}
