.container {
  display: flex;
  width: 100%;
}

.containerPast {
  composes: container;
  opacity: 0.6;
}

.left {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: normal;
  cursor: pointer;
}

.left :last-child {
  margin-top: 0.5rem;
}

.date {
  font-size: 0.9rem;
}

.type {
  font-weight: normal;
  margin-left: auto;
  font-size: 1rem;
  align-self: center;
}
