.container {
  display: flex;
  flex-flow: column;
  color: #6d6e71;
  height: min-content;
  width: 100%;
  box-sizing: border-box;
}

.container label {
  font-size: 0.8rem;
  width: 100%;
  padding-bottom: 0.2rem;
}

.container i {
  color: var(--errorRed);
  font-size: 1.5rem;
  padding-right: 2px;
  opacity: 1;
  transition: opacity;
}

.opal {
  opacity: 0;
}

.inputContainer {
  display: flex;
  width: 100%;
}

.inputContainer i {
  color: gray;
}

.unit {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #6d6e71;
  height: 100%;
  width: 3rem;
  margin-right: calc(-1 * var(--textInputPadding));
}

.textarea {
  height: 100%;
}

.input textarea {
  padding: 3px 4px 3px 4px;
  resize: none;
  flex-grow: 2;
  outline: none;
  height: 100%;
  width: 100%;
  background-color: #6d6e7100;
  height: inherit;
  border-radius: inherit;
  box-sizing: border-box;
  font-size: 1rem;
  overflow: hidden;
}

.input .iconCont {
  visibility: hidden;
  width: min-content;
}

.visible {
  composes: appear from "../../global-styles/index.scss";
  visibility: visible;
}

.invisible {
  visibility: hidden;
}
