@import "../../global-styles/variables";

.container {
  h1 {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  @include mobile {
    width: 100%;
  }
}

.form {
  width: 20rem;
  margin-bottom: 2rem;

  @include mobile {
    width: 100%;
  }
}

.success {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.success i {
  color: #76c548;
  margin-left: 1rem;
  font-size: 1.5rem;
}
