@import "../../global-styles/variables";

.container {
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.calendarContainer {
  position: fixed;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.12);
  z-index: 1;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  .resetButton {
    background-color: var(--blueBackground);
    color: black;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    margin-bottom: 1rem;
    transition: opacity 100ms ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  @include mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem !important;

  p {
    background-color: var(--blueBackground);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    border-radius: 1.5rem;
    margin-bottom: 0 !important;
    transition: opacity 100ms ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }

    i {
      margin-right: 0.5rem;
      font-size: 1.3rem;
    }
  }
}
