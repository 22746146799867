.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container p {
  cursor: pointer;
  font-weight: normal;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue);
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  margin-left: auto;
}

.button i {
  font-size: 1.3rem;
  padding-left: 0.3rem;
}
