@import "../../global-styles/variables";

.basicData {
  display: flex;
  margin-left: -0.6rem;

  @include mobile {
    margin-left: 0;
  }
}

.backArrow {
  font-size: 3.5rem;
  margin-right: 0.5rem;
  cursor: pointer;

  @include mobile {
    display: none;
  }
}

.firstRow {
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  > *:last-child {
    margin-right: 0;
  }
  i {
    font-size: 1.7rem;
  }
}

.secondRow {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  opacity: 0.6;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  > * {
    margin-right: 0.5rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  i {
    font-size: 1rem;
    margin-right: 0;
  }

  img {
    height: 0.9rem;
    width: auto;
    margin-right: 0.5rem;
  }
}

.time {
  display: flex;
  align-items: center;
  i {
    margin-right: 0.3rem;
  }
}
