@import "../../global-styles/variables";

.container {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0);

  @include desktop {
    margin-left: var(--sideBarWidth);
    width: calc(100vw - var(--sideBarWidth));
    height: 100vh;
  }

  @include mobile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
  }
}

.container.visible {
  background-color: rgba(0, 0, 0, 0.25);
}

.panel {
  background-color: white;
  overflow-y: scroll;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.25);
  padding: 1rem;

  @include desktop {
    min-width: 35rem;
    max-height: 80vh;
    border-radius: 1rem;
  }

  @include mobile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.panel::-webkit-scrollbar {
  display: none;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.66rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgb(220, 220, 220);
}

.panel h1 {
  font-size: 1.5rem;
}

.top i {
  cursor: pointer;
  font-size: 2rem;
  transition: opacity 0.1s ease-in-out;
}

.top i:hover {
  opacity: 0.7;
}

.tableTitle {
  display: flex;

  @include desktop {
    align-items: center;
  }

  @include mobile {
    flex-direction: column;
  }

  > h2 {
    user-select: none;

    @include desktop {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 1.2rem;
      font-weight: 600;
    }

    @include mobile {
      font-size: 1.1rem;
    }
  }

  > p {
    margin-left: 1rem;
    font-weight: 100;
    color: #888;
    font-size: 1rem;

    @include mobile {
      display: none;
    }
  }
}

.form h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: -0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}

.form h2 i {
  margin-left: 0.5rem;
}

.form h2:hover {
  opacity: 0.7;
}

.form h2:active {
  transform: translateY(-0.1rem);
}

.formLabel {
  margin-top: 1rem;
}

.form {
  padding-left: 1.5rem;
  padding-right: 2rem;
}

.tableDataContainer {
  composes: tableDataContainer from "../../common-components/OutpatientSheetDataTable/OutpatientSheetDataTable.module.scss";
}

.tableDataRow:nth-child(even) {
  background-color: #f5f5f5;
}

.tableDataRow p:first-child {
  width: 10rem;
}

.tableDataRow {
  composes: tableDataRow from "../../common-components/OutpatientSheetDataTable/OutpatientSheetDataTable.module.scss";
}

.tableDataRow:hover {
  opacity: 0.7;
}

.tableDataRow:active {
  transform: translateY(-0.05rem);
}

.tableData {
  font-weight: 100;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -2rem;
}

.buttonContainer > * {
  margin-left: 2rem; /*flex gap equivalent for safari*/
}

.popupContent {
  text-align: center;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
}
